import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import navConfig from '../../config/nav.json';
import INavItem from '../../models/INavItem';
import { useHistory } from 'react-router-dom';
import { checkPath, resolvePath } from './util';
import { useParamsStore } from '../../stores';
import { cloneDeep } from 'lodash';

export default function useNavConfig() : [INavItem[] | null, Dispatch<SetStateAction<INavItem[] | null>>] {
  const [ nav, setNav ] = useState<INavItem[] | null>(null);
  const [ params, setParams ] = useState<{ [key: string]: string | undefined }>({});

  const history = useHistory();
  const paramsStore = useParamsStore();

	const updateNavItems = () => {
		for (let configItem of navConfig) {
			if (checkPath(window.location.pathname, configItem.includes)) {
				setNav(resolvePath(cloneDeep(configItem.items), params));
				return;
			}
    }
    setNav(resolvePath(cloneDeep(navConfig[0].items), params));
	}

  useEffect(() => {
    setParams(paramsStore.params);
  }, [paramsStore.params])

  useEffect(() => {
    updateNavItems();
  }, [params])

	useEffect(() => {
		updateNavItems();
	}, []);

	history.listen(() => {
		updateNavItems();
	})


	return [
		nav,
		setNav
	];
}