import React, { useEffect, useState } from 'react';
import './ChatSection.scss';
import { observer } from 'mobx-react-lite';
import {
  useUserStore, useSelectedLaunchStore, useChatStore, useOverlayStore,
} from '../../stores';
import Button from '../Button/Button';
import BottomOverlayHeader from '../BottomOverlayHeader/BottomOverlayHeader';
import ShareList from '../ShareList/ShareList';
import AddComment from '../AddComment/AddComment';
import ChatHistory from '../ChatHistory/ChatHistory';
import MemberList from '../MemberList/MemberList';

export default observer(() => {
  const userStore = useUserStore();
  const selectedLaunchStore = useSelectedLaunchStore();
  const chatStore = useChatStore();
  const overlayStore = useOverlayStore();

  const [chatId, setChatId] = useState<string | number | null>(null);
  const [checkedStore, setCheckedStore] = useState<boolean>(false);

  useEffect(() => {
    if (!checkedStore) {
      if (selectedLaunchStore.item && userStore.user) {
        chatStore.hasChat(selectedLaunchStore.item.id, userStore.user.uid).then((response) => {
          if (response) {
            chatStore.listenToChat(response);
          } else {
            chatStore.currentChatId = null;
            chatStore.chat = null;
          }
          setChatId(response);

          setCheckedStore(true);
        });
      }
    }

    return () => {
      if (chatId) {
        chatStore.removeCurrentChatListener();
      }
    };
  }, []);

  const shareChat = (id: string | number) => {
    overlayStore.setOverlay({
      content: () => {
        if (!selectedLaunchStore.item) return null;
        return (
          <div className="share-overlay">
            <BottomOverlayHeader title="Share" />
            <ShareList
              url={`https://slp.netlify.com/launch/${selectedLaunchStore.item.id}/invite/${id}`}
              launch={selectedLaunchStore.item}
            />
          </div>
        );
      },
      overlayBodyClassName: 'overlay__body--white',
      isBottom: true,
      withoutExit: true,
    });
  };

  const memberList = () => {
    overlayStore.setOverlay({
      content: () => {
        if (!chatStore.chat || !selectedLaunchStore.item) return null;
        return (
          <div className="share-overlay">
            <BottomOverlayHeader title="Member" />
            <MemberList
              launchId={selectedLaunchStore.item.id}
              chatId={chatId}
            />
          </div>
        );
      },
      overlayBodyClassName: 'overlay__body--white',
      isBottom: true,
      withoutExit: true,
    });
  };

  const leaveChatroom = async () => {
    if (chatId && userStore.user && selectedLaunchStore.item) {
      await chatStore.removeUserFromChat(selectedLaunchStore.item.id, chatId, userStore.user);
      chatStore.currentChatId = null;
      chatStore.chat = null;
      chatStore.removeCurrentChatListener();
      overlayStore.exitOverlay();
    }
  };

  useEffect(() => {
    setChatId(chatStore.currentChatId);
  }, [chatStore.currentChatId]);

  const invite = async () => {
    if (selectedLaunchStore.item && userStore.user) {
      const id = await chatStore.createChat(selectedLaunchStore.item.id, userStore.user);

      if (id) {
        shareChat(id);
        setChatId(id);
      }
    }
  };

  if (!selectedLaunchStore.item || !userStore.user || !checkedStore) return null;

  return (
    <div className="chat-section">
      {!chatId
        ? (
          <div className="chat-section__invite">
            <Button
              onClick={invite}
              isPrimary
            >
              Invite some friends
            </Button>
          </div>
        )
        : (
          <div className="chat-section__active">
            <div className="chat-section__nav">
              <Button
                onClick={memberList}
                isIconFloating
                icon={{ type: 's', name: 'users' }}
              />
              <Button
                onClick={() => shareChat(chatId)}
                isIconFloating
                icon={{ type: 'l', name: 'plus' }}
              />
              <Button
                onClick={leaveChatroom}
                isIconFloating
                icon={{ type: 'l', name: 'sign-out' }}
              />
            </div>
            <ChatHistory />
            <AddComment />
          </div>
        )}

    </div>
  );
});
