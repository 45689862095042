import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import './Message.scss';
import classNames from 'classnames';
import { useMessageStore } from '../../stores';
import { MessageType } from '../../models/IMessage';

export default observer(() => {
  const messageStore = useMessageStore();

  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (messageStore.message) {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setTimeout(() => {
          messageStore.reset();
        }, 500);
      }, 2500);
    }
  }, [messageStore.message]);

  if (!messageStore.message) return null;

  return (
    <div className={classNames({
      message: true,
      'message--visible': visible,
      'message--error': messageStore.message.type === MessageType.error,
      'message--success': messageStore.message.type === MessageType.success,
      'message--warning': messageStore.message.type === MessageType.warning,
    })}
    >
      {messageStore.message.content}
    </div>
  );
});
