import React from 'react';
import { observer } from 'mobx-react';
import { useUserStore } from '../../stores';
import ILaunch from '../../models/ILaunch';
import Button from '../Button/Button';
import useShareing from '../../hooks/share';
import { useFav, useAddToList } from '../../hooks';
import './SocialButtons.scss';

interface SocialButtonsProps {
  launch: ILaunch
}

export default observer(({ launch } : SocialButtonsProps) => {
  const [shareHandler] = useShareing(launch);
  const [favHandler, isFavourite] = useFav(launch);
  const [listHandler] = useAddToList(launch);

  const userStore = useUserStore();

  return (
    <div className="social-buttons">
      {userStore.user
        ? (
          <>
            {isFavourite
              ? (
                <Button
                  onClick={favHandler}
                  className="social-buttons__heart preview-card__nav__item--heart--active"
                  icon={{ type: 's', name: 'heart' }}
                />
              )
              : (
                <Button
                  className="social-buttons__heart"
                  onClick={favHandler}
                  icon={{ type: 'r', name: 'heart' }}
                />
              )}
            <Button
              onClick={listHandler}
              icon={{ type: 's', name: 'bookmark' }}
            />
          </>
        )
        : null}
      <Button
        className="social-buttons__share"
        onClick={shareHandler}
        icon={{ type: 's', name: 'share' }}
      />
    </div>
  );
});
