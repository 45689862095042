import React, { useState, ReactNode } from 'react';
import { observer } from 'mobx-react';
import './Accordion.scss';
import classNames from 'classnames';
import Icon from '../Icon/Icon';

interface AccordionProps {
    defaultStateClosed?: boolean,
    children: ReactNode,
    headline: string
}

export default observer(({ defaultStateClosed = false, children, headline } : AccordionProps) => {
  const [closed, setClosed] = useState<boolean>(defaultStateClosed);

  return (
    <div className="card accordion">
      <div
        role="button"
        tabIndex={0}
        onKeyUp={(e) => (e.which === 13 ? setClosed(!closed) : null)}
        className={classNames({
          accordion__header: true,
          'accordion__header--closed': closed,
        })}
        onClick={() => setClosed(!closed)}
      >

        <h4 className="h4 accordion__header__headline">{headline}</h4>
        <div
          className={classNames({
            icon: true,
            accordion__header__arrow: true,
            'accordion__header__arrow--closed': closed,
          })}
        >
          <Icon icon="angle-down" faType="l" />
        </div>
      </div>
      <div
        className={classNames({
          accordion__body: true,
          'accordion__body--closed': closed,
        })}
      >
        {children}
      </div>
    </div>
  );
});
