import React from 'react';
import { observer } from 'mobx-react';
import { useSelectedLaunchStore } from '../../stores';
import { useInitDetail } from '../../hooks';
import './Launch.scss';
import LaunchDetail from '../../components/LaunchDetail/LaunchDetail';
import Accordion from '../../components/Accordion/Accordion';
import Map from '../../components/Map/Map';
import LaunchCountdown from '../../components/LaunchCountdown/LaunchCountdown';
import Livestream from '../../components/Livestream/Livestream';
import Loader from '../../components/Loader/Loader';

export default observer(() => {
  const selectedLaunchStore = useSelectedLaunchStore();

  useInitDetail();

  if (!selectedLaunchStore.item) {
    return (
      <Loader isWhite isLoading={selectedLaunchStore.loading}><></></Loader>
    );
  }

  return (
    <div className="launch container">
      <div className="launch__section">
        {selectedLaunchStore.item.vidUrl
          ? (
            <Livestream
              url={selectedLaunchStore.item.vidUrl}
            />
          )
          : (
            <LaunchCountdown
              date={selectedLaunchStore.item.date}
              isWhite
              title="Launch"
            />
          )}

      </div>
      <div className="launch__section">
        <LaunchDetail launch={selectedLaunchStore.item} />
      </div>
      {selectedLaunchStore.item.mission
        ? (
          <div className="launch__section">
            <Accordion headline="Description" defaultStateClosed>
              <p className="p">{selectedLaunchStore.item.mission.description}</p>
            </Accordion>
          </div>
        )
        : null}

      {selectedLaunchStore.item.location && selectedLaunchStore.item.location.pad
        ? (
          <div className="launch__section">
            <Map
              lng={selectedLaunchStore.item.location.pad.lng}
              lat={selectedLaunchStore.item.location.pad.lat}
              name={selectedLaunchStore.item.location.pad.name}
              agency={selectedLaunchStore.item.lsp.name}
            />
          </div>
        )
        : null}

    </div>
  );
});
