import React from 'react';
import { observer } from 'mobx-react';
import { Redirect, useParams } from 'react-router-dom';
import { useParamsStore, useChatStore, useUserStore } from '../../stores';

export default observer(() => {
  const userStore = useUserStore();
  const chatStore = useChatStore();
  const paramsStore = useParamsStore();
  paramsStore.setParams(useParams());

  const { id, chatId } = useParams();

  if (id && chatId && userStore.user) {
    (async () => {
      if (id && chatId && userStore.user) {
        await chatStore.addUserToChat(parseInt(id, 10), chatId, userStore.user);
        chatStore.currentChatId = chatId;
        chatStore.listenToChat(chatId);
      }
    })();
  }

  return (
    <Redirect to={`/launch/${id}/chat`} />
  );
});
