import { observable } from 'mobx';
import IOverlay from '../models/IOverlay';

class OverlayStore {

	constructor() {
		this.exitOverlay = this.exitOverlay.bind(this);
		this.setOverlay = this.setOverlay.bind(this);
	}

	@observable overlay: IOverlay | null = null
	
	public exitOverlay() {
		this.overlay = null;
	}


	public setOverlay(overlay: IOverlay) {
		this.overlay = overlay;
	}
}

export default new OverlayStore();
