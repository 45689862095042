import React, { FormEvent } from 'react';
import './AddComment.scss';
import { observer } from 'mobx-react';
import { useInput } from 'rooks';
import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import { useChatStore, useUserStore } from '../../stores';

export default observer(() => {
  const newComment = useInput('');

  const chatStore = useChatStore();
  const userStore = useUserStore();

  const addComment = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (userStore.user && newComment.value && newComment.value !== '') {
      await chatStore.addComment(newComment.value, userStore.user);
      newComment.onChange({
        target: {
          value: '',
        },
      });
    }
  };

  return (
    <form
      onSubmit={addComment}
      className="add-comment"
    >
      <TextField
        {...newComment}
        isSecondary
        className="add-comment__input"
        placeholder="Say something..."
        name="comment"
      />
      <Button
        className="add-comment__send"
        type="submit"
        icon={{ type: 's', name: 'paper-plane' }}
      />
    </form>
  );
});
