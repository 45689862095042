import React from 'react';
import './ChatBubble.scss';
import { observer } from 'mobx-react';
import classNames from 'classnames';

interface ChatProps {
    message: {
        content: string,
        name?: string
    },
    self?: boolean
}

export default observer(({ message, self = false } : ChatProps) => (
  <div className={classNames({
    'chat-bubble': true,
    'chat-bubble--other': !self,
    'chat-bubble--self': self,
  })}
  >
    {message.name
      ? (
        <h4 className="chat-bubble__user-name">
          {message.name}
          :
        </h4>
      )
      : null}
    <p className="chat-bubble__text">{message.content}</p>
  </div>
));
