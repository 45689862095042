import React from 'react';
import { observer } from 'mobx-react';
import './Map.scss';
import ReactMapboxGl, { Marker, Popup } from 'react-mapbox-gl';
import Icon from '../Icon/Icon';

interface MapProps {
  lat: number,
  lng: number,
  name: string,
  agency: string
}

export default observer(({
  lat, lng, name, agency,
} : MapProps) => {
  const zoom: number = 5;

  const Map = ReactMapboxGl({
    accessToken:
      'pk.eyJ1IjoidG9iaWFzZnJhbmVrIiwiYSI6ImNreThobGUweDEwM2sydnBmZzBocWl3NGcifQ.yjsAcGO9WI5j0N-ydAEwYQ',
    interactive: false,
  });
  /* eslint-disable react/style-prop-object */
  return (
    <div className="map">
      <Map
        style="mapbox://styles/tobiasfranek/ck67sm2130nhc1iqgnthaimln"
        containerStyle={{
          height: '400px',
          width: '100%',
          borderRadius: '16px',
        }}
        zoom={[zoom]}
        center={[lng, lat]}
      >
        <Popup
          anchor="bottom"
          coordinates={[lng, lat]}
          offset={[0, -54]}
        >
          <div className="map__popup">
            <h3 className="h5 map__popup__headline">{name}</h3>
            <p className="p map__popup__p">{agency}</p>
          </div>
        </Popup>

        <Marker
          coordinates={[lng, lat]}
          anchor="bottom"
        >
          <Icon className="rocket-icon" icon="rocket-launch" faType="d" />
        </Marker>
      </Map>

    </div>
  );
  /* eslint-enable react/style-prop-object */
});
