import React, { useState, useEffect } from 'react';
import './MemberList.scss';
import { observer } from 'mobx-react';
import IUser from '../../models/IUser';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import { useChatStore, useUserStore, useOverlayStore } from '../../stores';

interface MemberListProps {
  chatId: string | number | null,
  launchId: number
}

export default observer(({ chatId, launchId } : MemberListProps) => {
  const chatStore = useChatStore();
  const userStore = useUserStore();
  const overlayStore = useOverlayStore();

  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (chatStore.chat && chatStore.chat.users && userStore.user) {
      /* eslint-disable no-restricted-syntax */
      for (const user of Object.values(chatStore.chat.users)) {
        if (user.uid === userStore.user.uid) {
          if (user.admin) {
            setIsAdmin(true);
            return;
          }
        }
      }
      /* eslint-enable no-restricted-syntax */
    }
    setIsAdmin(false);
  }, [chatStore.chat]);

  const removeUser = async (user: IUser) => {
    if (chatId && userStore.user) {
      await chatStore.removeUserFromChat(launchId, chatId, user);
      if (userStore.user.uid === user.uid) {
        chatStore.currentChatId = null;
        chatStore.chat = null;
        chatStore.removeCurrentChatListener();
      }
      overlayStore.exitOverlay();
    }
  };

  if (!chatStore.chat || !chatStore.chat.users || !userStore.user) return null;

  return (
    <ul className="ul custom-list">
      {Object.values(chatStore.chat.users).map((user) => (
        <li key={user.uid} className="ul__li custom-list__item">
          <div className="member-list__first-items">
            <Icon className="member-list__user" faType="r" icon="user-astronaut" />

            <span className="custom-list__item__name">{user.displayName}</span>
          </div>
          {isAdmin
            ? (
              <Button
                onClick={() => removeUser(user)}
                icon={{ type: 's', name: 'trash' }}
              />
            )
            : null}

        </li>
      ))}
    </ul>
  );
});
