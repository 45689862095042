import React from 'react';
import { observer } from 'mobx-react';
import './Imprint.scss';

export default observer(() => (
  <div className="imprint landing container">
    <p className="p">Multimedia project 2a</p>
    <p className="p">Space Launch Plattform</p>
    <p className="p">Multimedia Technology FH Salzburg</p>
    <p className="p">Contact:</p>
    <p className="p"><a className="a" href="mailto:tobias.franek@gmail.com">tobias.franek@gmail.com</a></p>
    <p className="p">Website:</p>
    <p className="p"><a className="a" href="https://bitminds.at">bitminds.at</a></p>
  </div>
));
