import React from 'react';
import './UserHeader.scss';
import { observer } from 'mobx-react';
import Icon from '../Icon/Icon';

export default observer(() => (
  <div className="user-header">
    <div className="container user-header__profile-wrapper">
      <div className="user-header__profile-icon">
        <Icon icon="user-astronaut" faType="s" />
      </div>
    </div>
  </div>
));
