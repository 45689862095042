import React from 'react';
import { observer } from 'mobx-react';
import './Livestream.scss';
import ReactPlayer from 'react-player';

interface LivestreamProps {
  url: string
}

export default observer(({ url } : LivestreamProps) => (
  <ReactPlayer
    className="livestream"
    url={url}
    playing
  />
));
