import { observable, action } from 'mobx';
import ILaunch from '../models/ILaunch';
import launchAPI from '../api/launches';
import messageStore from './MessageStore';
import { MessageType } from '../models/IMessage';

class SelectedLaunchStore {
  @observable item: ILaunch | null = null;

  @observable loading: boolean = false;

  @action async fetch(id: string) {
    this.loading = true;
    try {
      const launches = await launchAPI.get({
        id: id
      });

      if (launches[0]) {
        this.item = launches[0];
      }
    } catch {
      messageStore.setMessage({
        content: 'Failed to fetch launch.',
        type: MessageType.error
      });
    } finally {
      this.loading = false;
    }
  }

  @action setItem(item: ILaunch) {
    this.item = item;
  }
}

export default new SelectedLaunchStore();
