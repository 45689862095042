import { observable, action } from 'mobx';
import ILaunch from '../models/ILaunch';
import launchAPI from '../api/launches';
import messageStore from './MessageStore';
import { MessageType } from '../models/IMessage';

class LaunchStore {
	@observable launches: ILaunch[] | null = null;
  @observable isLoading: boolean = false;

  currentLimit: number = 10;
  offset: number = 0;

  @action async fetch() {
    try {
      this.isLoading = true;
      const launches = await launchAPI.get({
        limit: this.currentLimit,
        offset: this.offset
      });
  
      this.launches = launches;
    } catch {
      messageStore.setMessage({
        content: 'Failed to fetch launches.',
        type: MessageType.error
      })
    } finally {
      this.isLoading = false;
    }
  }

  @action setLaunches(launches: ILaunch[]) {
    this.launches = launches;
  }

  @action reset() {
    this.currentLimit = 20;
    this.offset = 0;
    this.launches = null;
  }

  @action async setPagination(limit: number, offset: number) {
    this.currentLimit = limit;
    this.offset = offset;

    await this.fetch();
  }
}

export default new LaunchStore();
