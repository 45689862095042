import React from 'react';
import { useOverlayStore } from '../stores';
import BottomOverlayHeader from '../components/BottomOverlayHeader/BottomOverlayHeader';
import ShareList from '../components/ShareList/ShareList';
import ILaunch from '../models/ILaunch';

export default function useShareing(launch: ILaunch, url?: string) {
  const overlayStore = useOverlayStore();
  let shareUrl = url;
  if (!shareUrl) {
    shareUrl = `https://slp.netlify.com/launch/${launch.id}`;
  }

  const handler = () => {
    overlayStore.setOverlay({
      content: () => (
        <div className="share-overlay">
          <BottomOverlayHeader title="Share" />
          <ShareList url={shareUrl as string} launch={launch} />
        </div>
      ),
      overlayBodyClassName: 'overlay__body--white',
      isBottom: true,
      withoutExit: true,
    });
  };
  return [
    handler,
  ];
}
