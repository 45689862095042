import React from 'react';
import './Navigation.scss';
import { observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useNavConfig } from '../../hooks';
import Icon from '../Icon/Icon';

export default observer(() => {
  const history = useHistory();
  const [navItems] = useNavConfig();

  if (!navItems || navItems.length === 0) return null;

  return (
    <nav className="nav">
      <ul className="nav__list">
        {navItems.map((item) => (
          <li key={item.path} className="nav__list__item">
            <Link
              to={item.path}
              className={`nav__list__item__link ${classNames({
                'nav__list__item__link--active': item.path === history.location.pathname,
              })}`}
            >

              <span className="icon nav__list__item__link__icon">
                <Icon {...item.icon} />
              </span>
              {item.name}
            </Link>
          </li>
        ))}

      </ul>
    </nav>
  );
});
