import React, { useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useSelectedLaunchStore, useOverlayStore } from '../../stores';
import { useInitDetail } from '../../hooks';
import './Vehicle.scss';
import Accordion from '../../components/Accordion/Accordion';
import RocketDetail from '../../components/RocketDetail/RocketDetail';
import Loader from '../../components/Loader/Loader';

export default observer(() => {
  const selectedLaunchStore = useSelectedLaunchStore();
  const { setOverlay } = useOverlayStore();

  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  useInitDetail();

  if (!selectedLaunchStore.item) {
    return (
      <Loader isWhite isLoading={selectedLaunchStore.loading}><></></Loader>
    );
  }

  const { rocket } = selectedLaunchStore.item;

  const openImage = (sources: { [key: string]: string }, alt: string) => {
    setOverlay({
      content: () => (
        <>
          <div className="vehicle__overlay-image__loader">
            <Loader isWhite isLoading><></></Loader>
          </div>
          <picture>
            <source media="(min-width: 576px)" srcSet={sources['640']} />
            <source media="(min-width: 768px)" srcSet={sources['768']} />
            <source media="(min-width: 992px)" srcSet={sources['960']} />
            <source srcSet={sources['1440']} />
            <img
              className="vehicle__overlay-image"
              srcSet={sources['1440']}
              alt={alt}
            />
          </picture>
        </>
      ),
    });
  };

  return (
    <div className="launch vehicle container">
      <div className="launch__section">
        <div className={classNames({
          'vehicle__image-wrapper': true,
          'vehicle__image-wrapper--loaded': isImageLoaded,
        })}
        >
          <picture>
            <source media="(min-width: 576px)" srcSet={rocket.imageFormats['640']} />
            <source media="(min-width: 768px)" srcSet={rocket.imageFormats['768']} />
            <source media="(min-width: 992px)" srcSet={rocket.imageFormats['960']} />
            <source srcSet={rocket.imageFormats['1080']} />
            <img
              role="button"
              tabIndex={0}
              onClick={() => openImage(rocket.imageFormats, rocket.name)}
              onKeyUp={() => openImage(rocket.imageFormats, rocket.name)}
              onLoad={() => setIsImageLoaded(true)}
              className={classNames({
                vehicle__image: true,
                'vehicle__image--loaded': isImageLoaded,
              })}
              srcSet={rocket.imageFormats['1440']}
              alt={rocket.name}
            />
          </picture>
        </div>
      </div>
      <div className="launch__section">
        <RocketDetail rocket={rocket} />
      </div>
      {rocket.description
        ? (
          <div className="launch__section">
            <Accordion headline="Description" defaultStateClosed>
              <p className="p">{rocket.description}</p>
            </Accordion>
          </div>
        )
        : null}

    </div>
  );
});
