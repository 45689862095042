import React from 'react';
import { CountdownRenderProps } from 'react-countdown';

export default ({
  days, hours, minutes, seconds,
} : CountdownRenderProps) => (
  <>
    <span>
      {days}
      d
      {' '}
    </span>
    <span>
      {hours}
      h
      {' '}
    </span>
    <span>
      {minutes}
      m
      {' '}
    </span>
    <span>
      {seconds}
      s
      {' '}
    </span>
  </>
);
