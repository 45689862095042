import { useEffect } from "react";

export default function useBodyBackground(color: string): void {
  useEffect(() => {
    const body = document.querySelector('body');

    if (body) {
      body.style.background = color;
    }
  }, []);
}