import { observable, action } from 'mobx';

class ParamsStore {

  @observable params: { [key: string]: string | undefined } = {};

  @action setParams(params: { [key: string]: string | undefined }) {
    this.params = params;
  }
}

export default new ParamsStore();
