import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import './Favourites.scss';
import { useHistory } from 'react-router-dom';
import { useFavouriteStore, useUserStore, useMessageStore } from '../../stores';
import { MessageType } from '../../models/IMessage';
import PlanetHeader from '../../components/PlanetHeader/PlanetHeader';
import LaunchList from '../../components/LaunchList/LaunchList';
import { useInitDefault } from '../../hooks';
import Loader from '../../components/Loader/Loader';

export default observer(() => {
  const favStore = useFavouriteStore();
  const userStore = useUserStore();
  const messageStore = useMessageStore();

  useInitDefault();

  const history = useHistory();

  if (!userStore.user) {
    messageStore.setMessage({
      content: 'You have to be logged in',
      type: MessageType.error,
    });
    history.push('/');
  }

  useEffect(() => {
    if (!favStore.items && userStore.user) {
      favStore.fetch(userStore.user);
    }
  }, []);

  return (
    <Loader isLoading={favStore.fetching}>
      <div className="favourites landing">
        {favStore.items && favStore.items.length > 0
          ? (
            <>
              <PlanetHeader nextDate={favStore.items[0].date} />
              <div className="container">
                <LaunchList
                  isBig
                  className="landing__list landing__list--margin-negative"
                  launches={favStore.items}
                  title="Favourites"
                />
              </div>
            </>
          )
          : (
            <div className="no-content">
              You don&apos;t have any Favourites yet.
            </div>
          )}
      </div>
    </Loader>
  );
});
