
export default function useFontawesomePostFix(type: string) : string | null {

	switch (type) {
		case 'l':
			return 'light';
		case 'd':
			return 'duotone';
		case 'b':
			return 'brands';
		case 'r':
			return 'regular';
		case 's':
			return 'solid';
		default:
			return null;
	}
}