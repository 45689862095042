interface IInstance {
	method?: string,
	signal?: AbortSignal,
	body?: string,
	headers: IHeader
}

interface IHeader {
	[key: string]: string
}

interface IPendingRequests {
	[key: string]: {
		[key: string]: AbortController
	}
}

interface IParameter {
	[key: string]: any
}

interface IRequestBuilder {
	path: string,
	headers?: IHeader,
	abort: boolean,
	type: string,
	parameter?: IParameter
}

interface IRequest {
	path: string,
	abort?: boolean,
	headers?: IHeader,
	parameter?: IParameter
}

class Request {
	private baseUrl?: string;

	private instance: IInstance = {
		headers: {
			'Content-Type': 'application/json;charset=UTF-8',
		},
	};

	private pendingRequests: IPendingRequests = {
		get: {},
		post: {},
		put: {},
		delete: {},
	};
	
	constructor() {
		// get the current api path from an enviroment variable
		this.baseUrl = process.env.REACT_APP_API_URL;
	}

	/**
	 * build a instance which will be used in the fetch request
	 * merges given headers with existing headers
	 * 
	 * @param method: string
	 * @param signal: AbortSignal
	 * @param headers: IHeader
	 */
	private buildInstance(method: string, signal: AbortSignal, headers: IHeader) {
		this.instance = {
			method: method,
			signal,
			headers: {
				...headers,
				...this.instance.headers,
			},
		};
	}

	/**
	 * executes a get request
	 * 
	 * @param options: IRequest
	 */
	public get({ abort = true, ...rest } : IRequest): Promise<any> {
		return this.request({type: 'get', abort, ...rest});
	}

	/**
	 * execute a post request
	 * 
	 * @param options: IRequest
	 */
	public async post({ abort = true, ...rest } : IRequest): Promise<any> {
		return this.request({type: 'post', abort, ...rest});
	}

	/**
	 * execute a delete request
	 * 
	 * @param options: IRequest
	 */
	public async delete({ abort = true, ...rest } : IRequest): Promise<any> {
		return this.request({type: 'delete', abort, ...rest});
	}

	/**
	 * execute a put request
	 * 
	 * @param options: IRequest
	 */
	public async put({ abort = true, ...rest } : IRequest): Promise<any> {
		return this.request({type: 'put', abort, ...rest});
	}


	/**
	 * this method build the requests
	 * 
	 * @param options: IRequestBuilder 
	 */
	private async request({ path, headers = {}, type, abort = true, parameter } : IRequestBuilder) : Promise<any> {
		const abortController = new AbortController();

		// signal will be used to abort the fetch request
		// if a similiar request is already pending
		const { signal } = abortController;

		// build the instance to execute a post request
		this.buildInstance(type.toUpperCase(), signal, headers);
		if (parameter) {
			// add the parameters to the instance body
			this.instance.body = JSON.stringify(parameter);
		}

		// if there is an existing post request on the same path, abort the old one
		const finishedRequest = this.handleAbort(abort, path, type, abortController);

		// receive a response from the api
		const response = await fetch(`${this.baseUrl}${path}?`, this.instance);

		// delete the pending request
		finishedRequest();

		// check if the response was successful
		await this.checkStatus(response);

		// return null, because delete is often 204 No Content
		if (type !== 'delete') {
			// return the json promise
			return response.json();
		} else {
			return null;
		}

	}

	/**
	 * checks if the response is succesful
	 * this method can handle certain error codes differently
	 * 
	 * @param response: any
	 */
	private async checkStatus(response: Response) {
        switch(response.status) {
            case 200:
            case 300:
            case 201:
            case 204:
                break;
            default:
				const error = await response.json();
                throw new Error(error.message);
        }
	}
	
	/**
	 * this method decides if an already existing request will be aborted
	 * 
	 * @param abort: boolean
	 * @param path: string
	 * @param type: string
	 * @param controller: AbortController
	 */
	private handleAbort(abort: boolean = true, path: string, type: string, controller: AbortController) : Function {
		// abort request
		if (abort && this.pendingRequests[type][path]) {
			this.pendingRequests[type][path].abort();
		}

		// push the current request into the pending requests
		this.pendingRequests[type][path] = controller;

		return () => {
			delete this.pendingRequests[type][path];
		}
	}
}

export default new Request();
