import React from 'react';
import { observer } from 'mobx-react';
import IRocket from '../../models/IRocket';
import './RocketDetail.scss';

interface RocketDetailProps {
  rocket: IRocket
}

export default observer(({ rocket } : RocketDetailProps) => (
  <div className="rocket-detail launch-detail card">
    <div className="launch-detail__section">
      <h2 className="launch-detail__header h3">{rocket.name}</h2>
    </div>
    {rocket.agency
      ? (
        <div className="launch-detail__section">
          <h3 className="launch-detail__section-header h4">Launch Agency:</h3>
          <p className="p">{rocket.agency.name}</p>
        </div>
      )
      : null }

  </div>
));
