import { observable, action, computed } from 'mobx';
import firebase from '../firebase';
import ILaunch from '../models/ILaunch';
import IUser from '../models/IUser';
import IList from '../models/IList';
import messageStore from './MessageStore';
import { MessageType } from '../models/IMessage';

class ListStore {
	@observable currentList: string | null = null;

  @observable lists: { [key: string]: IList } | null = null; 
  
  @observable fetched: boolean = false;

	@computed get selectedList() : ILaunch[] {
		if (this.lists && this.currentList) {
      const listKey = this.findListByName(this.currentList);

      if (!listKey) return [];

      if (!this.lists[listKey].launches) return [];

			return Object.values(this.lists[listKey].launches);			 
		} else {
			return [];
		}
	}

	@action setCurrentList(listName: string) {
		this.currentList = listName;
	}

	@action listenToLists(user: IUser) {
		try {
			firebase.database().ref(`/lists/${user.uid}`).on('value', (snapshot) => {
        this.fetched = true;
        this.lists = snapshot.val();
			});
		} catch (e) {
			messageStore.setMessage({
        content: 'Failed to connect to firebase.',
        type: MessageType.error
      });
		}
	}

	@action async addList(listName: string, user: IUser) {
		try {
			await firebase.database().ref(`/lists/${user.uid}`).push({
				name: listName
			});
			
		} catch(e) {
      messageStore.setMessage({
        content: 'Failed to add list.',
        type: MessageType.error
      });
		}
	}

	@action removeCurrentListListener(user: IUser) {
    this.fetched = false;
		firebase.database().ref(`/lists/${user.uid}`).off('value');
	}

	@action async addLaunch(listName: string, launch: ILaunch, user: IUser) {
		try {
      if (!this.lists) {
        const response = await firebase.database().ref(`/lists/${user.uid}`).once('value');
        this.lists = response.val();
      }
      const listKey = this.findListByName(listName);

      if (!listKey) return;

			await firebase.database().ref(`/lists/${user.uid}/${listKey}/launches/${launch.id}`).set(launch);
		} catch(e) {
			messageStore.setMessage({
        content: 'Failed to add launch to list.',
        type: MessageType.error
      });
		}
	}

  private findListByName(name: string) {
    for (let key in this.lists) {
      if (this.lists[key].name === name) {
        return key;
      }
    }
    return null
  }

  @action async removeList(listName: string, user: IUser) {
    try {
      const listKey = this.findListByName(listName);

      if (!listKey) return;
  
      await firebase.database().ref(`/lists/${user.uid}/${listKey}`).remove();
    } catch (e) {
      messageStore.setMessage({
        content: 'Failed to remove launch to list.',
        type: MessageType.error
      });
    }
    
  }

	@action async removeLaunch(listName: string, launchID: number, user: IUser) {
		try {
      const listKey = this.findListByName(listName);

      if (!listKey) return;

			await firebase.database().ref(`/lists/${user.uid}/${listKey}/launches/${launchID}`).remove();

		} catch(e) {
			messageStore.setMessage({
        content: 'Failed to remove launch from list.',
        type: MessageType.error
      });
		}
	}
}

export default new ListStore();
