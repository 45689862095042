import React, { InputHTMLAttributes } from 'react';
import './TextField.scss';
import { observer } from 'mobx-react';
import classNames from 'classnames';

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
    isPrimary?: boolean,
    isSecondary?: boolean,
    outerClassName?: string,
    labelClassName?: string
    error?: string
}

export default observer(({
  error,
  isPrimary = false,
  isSecondary = false,
  children,
  outerClassName,
  labelClassName,
  name,
  value,
  className,
  type = 'text',
  ...rest
} : TextFieldProps) => (
  <div className={`input-group ${outerClassName || ''}`}>
    {error && error !== '' && !isSecondary
      ? <span className="input-group__error">{error}</span>
      : null}

    {!isPrimary
      ? (
        <label
          className={`${classNames({
            'input-group__label': true,
            'input-group__label--secondary': isSecondary,
            'input-group__label--secondary--error': isSecondary && error && error !== '',
          })} ${labelClassName || ''}`}
          htmlFor={name}
        >
          {children}
        </label>
      )
      : null}

    <input
      {...rest}
      value={value}
      id={name}
      type={type}
      className={`${classNames({
        'input-group__input': true,
        'input-group__input--primary': isPrimary,
        'input-group__input--primary--focus': isPrimary && value && value !== '',
        'input-group__input--primary--error': isPrimary && error && error !== '',
        'input-group__input--secondary': isSecondary,
        'input-group__input--secondary--error': isSecondary && error && error !== '',
      })}${className ? ` ${className}` : ''}`}
    />

    {isPrimary
      ? (
        <label
          className={`${classNames({
            'input-group__label': true,
            'input-group__label--primary': true,
            'input-group__label--primary--focus': isPrimary && value && value !== '',
            'input-group__label--primary--error': isPrimary && error && error !== '',
          })} ${labelClassName || ''}`}
          htmlFor={name}
        >
          {children}
        </label>
      )
      : null}

  </div>
));
