import React from 'react';
import Router from './router';
import Message from './components/Message/Message';

export default () => (
  <div className="App">
    <Message />
    <Router />
  </div>
);
