import React from 'react';
import overlayStore from './OverlayStore';
import userStore from './UserStore';
import chatStore from './ChatStore';
import favouriteStore from './FavouriteStore';
import listStore from './ListStore';
import launchStore from './LaunchStore';
import messageStore from './MessageStore';
import selectedLaunchStore from './SelectedLaunchStore';
import paramsStore from './ParamsStore';

const StoreContext = React.createContext({
	overlayStore,
	userStore,
	chatStore,
	favouriteStore,
  listStore,
  launchStore,
  messageStore,
  selectedLaunchStore,
  paramsStore
});

export const useParamsStore = () => {
  return React.useContext(StoreContext).paramsStore;
}

export const useSelectedLaunchStore = () => {
  return React.useContext(StoreContext).selectedLaunchStore;
}

export const useMessageStore = () => {
  return React.useContext(StoreContext).messageStore;
}

export const useLaunchStore = () => {
  return React.useContext(StoreContext).launchStore;
}

export const useFavouriteStore = () => {
	return React.useContext(StoreContext).favouriteStore;
}

export const useListStore = () => {
	return React.useContext(StoreContext).listStore;
}

export const useOverlayStore = () => {
	return React.useContext(StoreContext).overlayStore;
}

export const useChatStore = () => {
	return React.useContext(StoreContext).chatStore;
}

export const useUserStore = () => {
	return React.useContext(StoreContext).userStore;
}

export default StoreContext;