import React from 'react';
import './Login.scss';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useInitDefault } from '../../hooks';
import Tabs from '../../components/Tabs/Tabs';
import LoginForm from '../../components/LoginForm/LoginForm';

export default observer(() => {
  useInitDefault();

  const tabs = [
    {
      path: '/login',
      name: 'Sign in',
    },
    {
      path: '/register',
      name: 'Sign up',
    },
  ];

  return (
    <div className="login">
      <Tabs nav={tabs} />
      <div className="login__wrapper container">
        <h1 className="h1 login__wrapper__headline">Welcome back</h1>
        <LoginForm />
        <Link className="a imprint-link" to="/imprint">Imprint</Link>
      </div>
    </div>
  );
});
