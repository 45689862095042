import React from 'react';
import { observer } from 'mobx-react';
import './ShareList.scss';
import { Facebook, Twitter, Url } from './ShareButtons';
import ILaunch from '../../models/ILaunch';

export interface ShareListProps {
  launch: ILaunch,
  url: string
}

export default observer(({ launch, url } : ShareListProps) => (
  <div className="share-list">
    <ul className="ul">
      <Facebook url={url} launch={launch} />
      <Twitter url={url} launch={launch} />
      <Url url={url} launch={launch} />
    </ul>
  </div>
));
