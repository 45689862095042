import React, { FormEvent, useState } from 'react';
import './ChangePass.scss';
import { observer } from 'mobx-react';
import { useInput } from 'rooks';
import { useHistory } from 'react-router-dom';
import { useInitDefault } from '../../hooks';
import UserHeader from '../../components/UserHeader/UserHeader';
import { useUserStore, useMessageStore } from '../../stores';
import TextField from '../../components/TextField/TextField';
import Button from '../../components/Button/Button';
import { MessageType } from '../../models/IMessage';

export default observer(() => {
  useInitDefault();

  const userStore = useUserStore();
  const messageStore = useMessageStore();

  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();

  if (!userStore.user) return null;

  const newPassword = useInput('');
  const newPasswordConfirm = useInput('');

  const changePass = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (newPassword.value === '' || !newPassword.value) {
      messageStore.setMessage({
        content: 'Password cannot be empty.',
        type: MessageType.error,
      });
      return;
    }

    if (newPasswordConfirm.value === '' || !newPasswordConfirm.value) {
      messageStore.setMessage({
        content: 'You have to confirm the Password.',
        type: MessageType.error,
      });
      return;
    }

    if (newPassword.value !== newPasswordConfirm.value) {
      messageStore.setMessage({
        content: 'The two passwords have to be the same.',
        type: MessageType.error,
      });
      return;
    }

    if (userStore.user) {
      try {
        await userStore.changePass(newPasswordConfirm.value);

        messageStore.setMessage({
          content: 'Successfully changed password.',
          type: MessageType.success,
        });

        history.push('/me');
      } catch (error) {
        messageStore.setMessage({
          content: error.message,
          type: MessageType.error,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="change-email">
      <UserHeader />
      <div className="user-info container">
        {userStore.user.displayName
          ? <h2 className="h2">{userStore.user.displayName}</h2>
          : null }

        <form
          onSubmit={changePass}
          className="change-email__form"
        >
          <TextField
            {...newPassword}
            type="password"
            name="password"
            isPrimary
          >
            New Password
          </TextField>

          <TextField
            {...newPasswordConfirm}
            type="password"
            name="passwordConfirm"
            isPrimary
          >
            Confirm new Password
          </TextField>

          <div className="change-email__form__footer">
            <Button
              type="submit"
              isLoading={loading}
              isIconFloating
              icon={{ type: 's', name: 'save' }}
            />
          </div>
        </form>
      </div>
    </div>
  );
});
