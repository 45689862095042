import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import headerConfig from '../../config/header.json';
import IHeaderConfig from '../../models/IHeaderConfig';
import { useHistory } from 'react-router-dom';
import { checkPath } from './util';


export default function useHeaderConfig() : [ IHeaderConfig, Dispatch<SetStateAction<IHeaderConfig>> ] {
	const [ header, setHeader ] = useState<IHeaderConfig>({
		backLink: '/',
    isBlack: false,
    rightIcon: 'user-link'
	});
	const history = useHistory();

	const updateHeaderConfig = () => {
		for (let configItem of headerConfig) {

			if (checkPath(window.location.pathname, configItem.includes)) {
				setHeader({
					backLink: configItem.backLink,
					isBlack: configItem.isBlack,
          rightIcon: configItem.rightIcon
				});
				return;
			}
    }
    setHeader({
      backLink: '/',
      isBlack: false,
      rightIcon: 'user-link'
    });
	}
	
	useEffect(() => {
		updateHeaderConfig();
	}, []);

	history.listen(() => {
		updateHeaderConfig();
	})

	return [
		header,
		setHeader
	];
}