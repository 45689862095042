import React, { useState } from 'react';
import './PlanetHeader.scss';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import LaunchCountdown from '../LaunchCountdown/LaunchCountdown';
import earth480 from '../../assets/images/earth_480.jpg';
import earth768 from '../../assets/images/earth_768.jpg';
import earth1080 from '../../assets/images/earth_1080.jpg';
import earth1440 from '../../assets/images/earth_1440.jpg';
import earth2048 from '../../assets/images/earth_2048.jpg';

interface PlanetHeaderProps {
  nextDate: string
}

export default observer(({ nextDate } : PlanetHeaderProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  return (
    <div className={classNames({
      'planet-header': true,
      'planet-header--loaded': isLoaded,
    })}
    >
      <picture>
        <source media="(max-width: 480px)" srcSet={earth480} />
        <source media="(max-width: 768px)" srcSet={earth768} />
        <source media="(max-width: 1080px)" srcSet={earth1080} />
        <source media="(max-width: 1440px)" srcSet={earth1440} />
        <source srcSet={earth2048} />
        <img
          onLoad={() => setIsLoaded(true)}
          className={classNames({
            'planet-header__image': true,
            'planet-header__image--loaded': isLoaded,
          })}
          srcSet={earth2048}
          alt="earth background"
        />
      </picture>

      <div className="planet-header__countdown">
        <LaunchCountdown isWhite date={nextDate} />
      </div>
    </div>
  );
});
