import React from 'react';
import { observer } from 'mobx-react';
import './LaunchList.scss';
import classNames from 'classnames';
import ILaunch from '../../models/ILaunch';
import PreviewCard from '../PreviewCard/PreviewCard';

interface LaunchListProps {
  launches: ILaunch[],
  title: string
  isBig?: boolean,
  className?: string
}

export default observer(({
  launches, title, isBig = false, className,
} : LaunchListProps) => (
  <>
    <div className={`launch-list ${className || ''}`}>
      <h2 className="h1">{title}</h2>
      <div className="launch-list__wrapper">
        <div className={classNames({
          'flex-nowrap': !isBig,
          'flex-wrap': isBig,
          'd-flex': true,
          'launch-list__list': true,
          'launch-list__list--small': !isBig,
          'launch-list__list--big': isBig,
        })}
        >
          {launches.map((launch) => (
            <PreviewCard key={launch.id} launch={launch} isBig={isBig} />
          ))}
        </div>
      </div>
    </div>
  </>
));
