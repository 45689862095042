import React from 'react';
import './Me.scss';
import { observer } from 'mobx-react';
import { useInitDefault } from '../../hooks';
import UserHeader from '../../components/UserHeader/UserHeader';
import UserInfo from '../../components/UserInfo/UserInfo';

export default observer(() => {
  useInitDefault();

  return (
    <div className="me">
      <UserHeader />
      <UserInfo />
    </div>
  );
});
