import React from 'react';
import './Chat.scss';
import { observer } from 'mobx-react';
import { useSelectedLaunchStore } from '../../stores';
import { useInitDetail } from '../../hooks';
import ChatSection from '../../components/ChatSection/ChatSection';
import Loader from '../../components/Loader/Loader';

export default observer(() => {
  const selectedLaunchStore = useSelectedLaunchStore();
  useInitDetail();

  if (!selectedLaunchStore.item) {
    return (
      <Loader isWhite isLoading={selectedLaunchStore.loading}><></></Loader>
    );
  }

  return (
    <div className="chat container">
      <div className="launch__section">
        <ChatSection />
      </div>
    </div>
  );
});
