// import messageStore from '../../stores/message';
// import { MessageType } from '../../models/message';

/**
 * handles errors that occur from fetch requests
 * automatically sets error message
 * @param error
 */
const handleError = (error: Error) => {
	if (error.name !== 'AbortError') {

		// messageStore.setMessage({
		// 	content: error.message,
		// 	type: MessageType.error
		// });
		console.error(error);
	}
};

export default handleError;
