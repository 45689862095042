/* global FB */
import React from 'react';
import { observer } from 'mobx-react';
import Icon from '../../Icon/Icon';
import { ShareListProps } from '../ShareList';

export default observer(({ url } : ShareListProps) => {
  const share = () => {
    FB.ui({
      method: 'share',
      href: url,
    }, () => {});
  };

  return (
    <li
      role="link"
      tabIndex={0}
      onClick={share}
      onKeyUp={(e) => (e.which === 13 ? share() : null)}
      className="share-list__list-item ul__li ul__li--without-border"
    >
      <span className="share-list__icon share-list__icon--facebook">
        <Icon className="share-list__icon__svg-wrapper" faType="b" icon="facebook-f" />
      </span>
      <span className="share-list__text">
        Facebook
      </span>
      <span className="share-list__angle-right">
        <Icon faType="l" icon="angle-right" />
      </span>
    </li>
  );
});
