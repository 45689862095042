import React from 'react';
import './Icon.scss';
import { observer } from 'mobx-react';
import SVG from 'react-inlinesvg';
import useFontawesomePostFix from '../../hooks/fontawesome-postfix';

interface IconProps {
    faType?: string,
    icon: string,
    className?: string,
}

export default observer(({ faType, icon, className = '' } : IconProps) => {
  let filename = icon;

  if (faType) {
    const type = useFontawesomePostFix(faType);
    filename = `${filename}${type ? `-${type}` : ''}`;
  }
  /* eslint-disable import/no-dynamic-require */
  /* eslint-disable global-require */
  // get icon by name (f.e. 'copy')
  const svg = require(`../../assets/icons/${filename}.svg`).default;
  /* eslint-enable import/no-dynamic-require */
  /* eslint-enable global-require */

  return (
    <div className={`icon ${className}`}>
      <SVG
        className="icon__svg"
        preProcessor={(code) => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
        src={svg}
      />
    </div>
  );
});
