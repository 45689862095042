import React from 'react';
import './BottomOverlayHeader.scss';
import { observer } from 'mobx-react';
import Button from '../Button/Button';
import { useOverlayStore } from '../../stores';

interface BottomOverlayHeaderProps {
  title: string
}

export default observer(({ title } : BottomOverlayHeaderProps) => {
  const { exitOverlay } = useOverlayStore();

  return (
    <div className="bottom-overlay-header">
      <h2 className="h3 bottom-overlay-header__headline">{title}</h2>
      <Button
        onClick={exitOverlay}
        className="bottom-overlay-header__exit"
        icon={{ type: 'l', name: 'times' }}
      />
    </div>
  );
});
