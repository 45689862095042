import React from 'react';
import { observer } from 'mobx-react';
import './Header.scss';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import Icon from '../Icon/Icon';
import logo from '../../assets/images/logo.svg';
import { useHeaderConfig } from '../../hooks';
import { useUserStore } from '../../stores';
import LogoutButton from '../LogoutButton/LogoutButton';

export default observer(() => {
  const userStore = useUserStore();

  const [headerConfig] = useHeaderConfig();
  const { backLink = '/', isBlack = false, rightIcon } = headerConfig;

  let right = null;

  if (rightIcon === 'user-link') {
    right = (
      <Link
        to={userStore.user ? '/me' : '/login'}
        className={`header__user${isBlack ? ' header__user--white' : ''}`}
      >
        <Icon faType="r" icon="user-astronaut" />
      </Link>
    );
  } else if (rightIcon === 'logout') {
    right = (
      <LogoutButton />
    );
  }

  return (
    <header className={`header${isBlack ? ' header--black' : ''}${backLink ? ' header--has-backlink' : ''}`}>

      {backLink
        ? (
          <Link
            to={backLink}
            className={`header__back${isBlack ? ' header__back--white' : ''}`}
          >

            <Icon faType="l" icon="angle-left" />
          </Link>
        )
        : null}

      <div className="header__logo">
        <Link to="/" className="header__logo__link">
          <SVG
            preProcessor={(code) => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
            src={logo}
          />
        </Link>
      </div>
      {right}
    </header>

  );
});
