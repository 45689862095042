import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import './Lists.scss';
import { useHistory } from 'react-router-dom';
import { useListStore, useUserStore, useMessageStore } from '../../stores';
import { MessageType } from '../../models/IMessage';
import CustomLists from '../../components/CustomLists/CustomLists';
import CustomListsHeader from '../../components/CustomListsHeader/CustomListsHeader';
import { useInitDefault } from '../../hooks';
import Loader from '../../components/Loader/Loader';

export default observer(() => {
  const listStore = useListStore();
  const userStore = useUserStore();
  const messageStore = useMessageStore();

  useInitDefault();

  const history = useHistory();

  if (!userStore.user) {
    messageStore.setMessage({
      content: 'You have to be logged in',
      type: MessageType.error,
    });
    history.push('/');
  }

  useEffect(() => {
    if (userStore.user) {
      listStore.listenToLists(userStore.user);
    }

    return () => {
      if (userStore.user) {
        listStore.removeCurrentListListener(userStore.user);
      }
    };
  }, []);

  return (
    <Loader isLoading={!listStore.fetched}>
      <div className="lists">

        <h2 className="h3 lists__headline">Launchlists</h2>
        <CustomListsHeader />

        {listStore.lists && Object.keys(listStore.lists).length > 0
          ? <CustomLists lists={Object.values(listStore.lists).map((list) => list.name)} />
          : (
            <div className="no-content">
              You don&apos;t have any Lists yet.
            </div>
          )}
      </div>
    </Loader>
  );
});
