import Request from './util/request';
import execute from './util/execute';
import ILaunch from '../models/ILaunch';

export default {
	async get(filter: { [key: string]: string | number }) : Promise<ILaunch[]> {

		let query = '';
		for (let item in filter) {
			query += `${item}=${filter[item]}&`
		}

		if (query === '') {
			return execute(() => Request.get({ path: '/launches'}));
		} else {
			query = `?${query.substring(0, query.length - 1)}`
			return execute(() => Request.get({ path: `/launches/${query}`}));
		}

	}
}