import React, { ReactNode } from 'react';
import './Loader.scss';
import { observer } from 'mobx-react';

interface LoaderProps {
  children: ReactNode,
  isLoading?: boolean,
  isWhite?: boolean
}

export default observer(({ children, isLoading = false, isWhite = false } : LoaderProps) => (
  <>
    {isLoading
      ? (
        <div className="sk-folding-cube">
          <div className={`sk-cube1 sk-cube ${isWhite ? 'sk-cube--white' : ''}`} />
          <div className={`sk-cube2 sk-cube ${isWhite ? 'sk-cube--white' : ''}`} />
          <div className={`sk-cube4 sk-cube ${isWhite ? 'sk-cube--white' : ''}`} />
          <div className={`sk-cube3 sk-cube ${isWhite ? 'sk-cube--white' : ''}`} />
        </div>
      )
      : children }
  </>
));
