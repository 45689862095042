import { useEffect } from "react";
import { useParamsStore, useSelectedLaunchStore } from "../../stores";
import { useParams } from "react-router-dom";
import useBodyBackground from "../style/body";

export default function useInitDetail(): [ string | undefined ] {
  const paramsStore = useParamsStore();
  const selectedLaunchStore = useSelectedLaunchStore();
  paramsStore.setParams(useParams());

  useBodyBackground('#333');

  const { id } = useParams();

  useEffect(() => {
    if (!selectedLaunchStore.item && id) {
      selectedLaunchStore.fetch(id);
    }
  }, []);

  return [
    id
  ]
}