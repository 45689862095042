import React from 'react';
import { CountdownRenderProps } from 'react-countdown';

interface RendererProps extends CountdownRenderProps {
    isBig: boolean
}

export default ({
  isBig, total, days, hours, minutes, seconds, completed,
} : RendererProps) => {
  if (completed) {
    return (
      <span
        className="preview-card__content__timer preview-card__content__timer--live"
      >
        Live
      </span>
    );
  }
  if (!isBig) {
    return (
      <span
        className="preview-card__content__timer"
      >
        {Math.floor(total / (60 * 60 * 1000))}
        hrs
      </span>
    );
  }
  return (
    <span
      className="preview-card__content__timer"
    >
      {days}
      d
      {' '}
      {hours}
      hrs
      {' '}
      {minutes}
      m
      {' '}
      {seconds}
      s
      {' '}
    </span>
  );
};
