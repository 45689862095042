import React, { FormEvent, useState } from 'react';
import { observer } from 'mobx-react';
import './ResetPassword.scss';
import { useInput } from 'rooks';
import { useHistory } from 'react-router-dom';
import TextField from '../../components/TextField/TextField';
import Button from '../../components/Button/Button';
import { useMessageStore, useUserStore } from '../../stores';
import { MessageType } from '../../models/IMessage';
import { useInitDefault } from '../../hooks';

export default observer(() => {
  const email = useInput();
  useInitDefault();

  const messageStore = useMessageStore();
  const userStore = useUserStore();

  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();

  const resetPassword = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (email.value && email.value !== '') {
      try {
        await userStore.resetPassword(email.value);
        messageStore.setMessage({
          content: 'Successfully sent reset link.',
          type: MessageType.success,
        });

        history.push('/login');
      } catch (error) {
        messageStore.setMessage({
          content: error.message,
          type: MessageType.error,
        });
      }
    } else {
      messageStore.setMessage({
        content: 'Email cannot be blank.',
        type: MessageType.error,
      });
    }

    setLoading(false);
  };

  return (
    <div className="reset-password container">
      <form
        onSubmit={resetPassword}
        className="change-email__form"
      >
        <TextField
          {...email}
          name="email"
          isPrimary
        >
          Email
        </TextField>

        <div className="change-email__form__footer">
          <Button
            className="login-form__submit"
            type="submit"
            isIconFloating
            isLoading={loading}
            icon={{ type: 'd', name: 'angle-right' }}
          >
            Send email
          </Button>
        </div>
      </form>
    </div>
  );
});
