import React, { FormEvent, useState } from 'react';
import './ChangeEmail.scss';
import { observer } from 'mobx-react';
import { useInput } from 'rooks';
import { useHistory } from 'react-router-dom';
import { useInitDefault } from '../../hooks';
import UserHeader from '../../components/UserHeader/UserHeader';
import { useUserStore, useMessageStore } from '../../stores';
import TextField from '../../components/TextField/TextField';
import Button from '../../components/Button/Button';
import { MessageType } from '../../models/IMessage';

export default observer(() => {
  useInitDefault();

  const userStore = useUserStore();
  const messageStore = useMessageStore();

  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);

  if (!userStore.user) return null;

  const newEmail = useInput(userStore.user.email);

  const changeEmail = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (newEmail.value) {
      if (userStore.user) {
        try {
          await userStore.changeEmail(newEmail.value);

          messageStore.setMessage({
            content: 'Successfully updated email.',
            type: MessageType.success,
          });

          history.push('/me');
        } catch (error) {
          messageStore.setMessage({
            content: error.message,
            type: MessageType.error,
          });
        }
      }
    } else {
      messageStore.setMessage({
        content: 'Email cannot be empty.',
        type: MessageType.error,
      });
    }

    setLoading(false);
  };

  return (
    <div className="change-email">
      <UserHeader />
      <div className="user-info container">
        {userStore.user.displayName
          ? <h2 className="h2">{userStore.user.displayName}</h2>
          : null }

        <form
          onSubmit={changeEmail}
          className="change-email__form"
        >
          <TextField
            {...newEmail}
            name="email"
            type="email"
            isPrimary
          >
            New email
          </TextField>

          <div className="change-email__form__footer">
            <Button
              type="submit"
              isLoading={loading}
              isIconFloating
              icon={{ type: 's', name: 'save' }}
            />
          </div>
        </form>
      </div>
    </div>
  );
});
