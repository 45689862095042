import React from 'react';
import './UserInfo.scss';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { useUserStore } from '../../stores';
import ChangeEmailLink from '../ChangeEmailLink/ChangeEmailLink';

export default observer(() => {
  const userStore = useUserStore();

  if (!userStore.user) return null;

  return (
    <div className="user-info container">
      {userStore.user.displayName
        ? <h2 className="h2">{userStore.user.displayName}</h2>
        : null }

      {userStore.user.email
        ? <ChangeEmailLink email={userStore.user.email} />
        : null }

      <div className="user-info__change-pass">
        <Link
          className="btn btn--primary"
          to="/me/change-pass"
        >
          Change password
        </Link>
      </div>

    </div>
  );
});
