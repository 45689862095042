import { observable, action } from 'mobx';
import IMessage from '../models/IMessage';

class MessageStore {
  @observable message: IMessage | null = null;

  @action setMessage(message: IMessage) {
    this.message = message;
  }

  @action reset() {
    this.message = null;
  }
}

export default new MessageStore();
