import React, { FormEvent, useState } from 'react';
import './LoginForm.scss';
import { observer } from 'mobx-react';
import { useInput } from 'rooks';
import { useHistory, Link } from 'react-router-dom';
import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import { useMessageStore, useUserStore } from '../../stores';
import { MessageType } from '../../models/IMessage';
import Icon from '../Icon/Icon';

export default observer(() => {
  const email = useInput('');
  const password = useInput('');

  const [loading, setLoading] = useState<boolean>(false);

  const messageStore = useMessageStore();
  const userStore = useUserStore();

  const history = useHistory();

  const login = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    if (email.value === '' || !email.value) {
      messageStore.setMessage({
        content: 'Email cannot be blank',
        type: MessageType.error,
      });
    }

    if (password.value === '' || !password.value) {
      messageStore.setMessage({
        content: 'Password cannot be blank',
        type: MessageType.error,
      });
    }

    try {
      await userStore.login(email.value, password.value);

      messageStore.setMessage({
        content: 'Successfully logged in.',
        type: MessageType.success,
      });

      history.push('/');
    } catch (error) {
      messageStore.setMessage({
        content: error.message,
        type: MessageType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const signInWithFacebook = async () => {
    try {
      await userStore.signInWithFacebook();

      messageStore.setMessage({
        content: 'Successfully logged in.',
        type: MessageType.success,
      });

      history.push('/');
    } catch (error) {
      messageStore.setMessage({
        content: error.message,
        type: MessageType.error,
      });
    }
  };

  const signInWithTwitter = async () => {
    try {
      await userStore.signInWithTwitter();

      messageStore.setMessage({
        content: 'Successfully logged in.',
        type: MessageType.success,
      });

      history.push('/');
    } catch (error) {
      messageStore.setMessage({
        content: error.message,
        type: MessageType.error,
      });
    }
  };

  return (
    <>
      <form
        onSubmit={login}
        className="change-email__form"
      >
        <TextField
          {...email}
          type="email"
          name="email"
          isPrimary
        >
          Email
        </TextField>

        <TextField
          {...password}
          type="password"
          name="password"
          isPrimary
        >
          Password
        </TextField>

        <div className="change-email__form__footer">
          <Link
            className="login-form__reset-pw"
            to="/reset-password"
          >
            Forgot Password?
          </Link>

          <Button
            className="login-form__submit"
            type="submit"
            isBorderBottom
            isLoading={loading}
            isIconFloating
            icon={{ type: 'd', name: 'angle-right' }}
          >
            Login
          </Button>
        </div>
      </form>
      <p className="p sign-in-with">or sign in with</p>
      <div className="social-media-login">
        <button
          type="button"
          className="social-media-login__icon social-media-login__icon--facebook"
          onClick={signInWithFacebook}
        >
          <Icon icon="facebook-f" faType="b" />
        </button>
        <button
          type="button"
          className="social-media-login__icon social-media-login__icon--twitter"
          onClick={signInWithTwitter}
        >
          <Icon icon="twitter" faType="b" />
        </button>
      </div>
    </>
  );
});
