import INavItem from "../../../models/INavItem";

export default function resolvePath(items: INavItem[] | null, params: { [key: string]: string | undefined }) : INavItem[] | null {
  if (items) {
    const paramKeys = Object.keys(params);
    
    for (let key in items) {
      for (let param of paramKeys) {
        if (params[param]) {
          items[key].path = items[key].path.replace(`:${param}`, params[param] as string);
        }
      }
    }
    return items;
  } else {
    return null;
  }
}