import { observable, action } from 'mobx';
import firebase from '../firebase';
import IChat from '../models/IChat';
import IUser from '../models/IUser';
import uuidv4 from 'uuid/v4';
import messageStore from './MessageStore';
import { MessageType } from '../models/IMessage';

class ChatStore {
	@observable chat: IChat | null = null;
	@observable currentChatId: string | number | null = null;

	@action async hasChat(launchId: number, userId: string):  Promise<string | number | null> {
		const chatroom = await firebase.database().ref(`/chatEntries/${launchId}/${userId}`).once('value');

		if (chatroom.val()) {
			return chatroom.val();
		} else {
			return null;
		}
	}

	@action listenToChat(chatID: string | number) {
		try {
			firebase.database().ref(`/chats/${chatID}`).on('value', (snapshot) => {
				if (snapshot.val()) {
					this.chat = snapshot.val();
				}
			});
			this.currentChatId = chatID;
		} catch (e) {
			messageStore.setMessage({
        content: 'Could not connect to firebase.',
        type: MessageType.error
      });
		}
	}

	@action removeCurrentChatListener() {
		if (this.currentChatId) {
			firebase.database().ref(`/chats/${this.currentChatId}`).off('value');
		}
	}

	@action async addComment(content: string, user: IUser) {
		if (this.currentChatId) {
			const commentId = uuidv4();
			try {
				await firebase.database().ref(`/chats/${this.currentChatId}/comments/${commentId}`).set({
					content: content,
					username: user.displayName,
					uid: user.uid,
					date: (new Date()).toISOString()
        });
			} catch (e) {
				messageStore.setMessage({
          content: 'Could not add comment.',
          type: MessageType.error
        });
			}
			
		}
	}

	@action async removeUserFromChat(launchId: number, chatID: string | number, user: IUser) {
		try {
			await firebase.database().ref(`/chats/${chatID}/users/${user.uid}`).remove();
      await firebase.database().ref(`/chatEntries/${launchId}/${user.uid}`).remove();

      messageStore.setMessage({
        content: 'Successfully removed user.',
        type: MessageType.success
      });
		} catch (e) {
      messageStore.setMessage({
        content: 'Failed to remove user from chat.',
        type: MessageType.error
      });
		}
		
	}

	@action async addUserToChat(launchId: number, chatID: string | number, user: IUser) {
		try {
			await firebase.database().ref(`/chatEntries/${launchId}/${user.uid}`).set(chatID);
			await firebase.database().ref(`/chats/${chatID}/users/${user.uid}`).set({
					...user,
					admin: false
      });
      
		} catch (e) {
			messageStore.setMessage({
        content: 'Failed to add user to chat.',
        type: MessageType.error
      });
		}
		
	}

	@action async createChat(launchId: number, user: IUser) {
		if ((await this.hasChat(launchId, user.uid)) === null) {
			try {
				const chatRoomId = uuidv4()

				await firebase.database().ref(`/chatEntries/${launchId}/${user.uid}`).set(chatRoomId);

				await firebase.database().ref(`/chats/${chatRoomId}`).set({
					launchId: launchId.toString(),
					users: {
						[user.uid]: {
							...user,
							admin: true
						}
					}
				});

        this.listenToChat(chatRoomId);
        return chatRoomId;
			} catch (e) {
				messageStore.setMessage({
          content: 'Something went wrong with creating the chat.',
          type: MessageType.error
        });
      }
    } 
    return null;
	}
}

export default new ChatStore();
