import React, { useState } from 'react';
import { observer } from 'mobx-react';
import './CustomListsHeader.scss';
import { useInput } from 'rooks';
import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import { useUserStore, useListStore, useMessageStore } from '../../stores';
import { MessageType } from '../../models/IMessage';

export default observer(() => {
  const userStore = useUserStore();
  const listStore = useListStore();
  const messageStore = useMessageStore();

  const [newListActive, setNewListActive] = useState<boolean>(false);
  const newList = useInput('');

  const addNewList = () => {
    if (newList.value) {
      if (userStore.user) {
        listStore.addList(newList.value, userStore.user);
        newList.value = '';
        setNewListActive(false);
        newList.onChange({
          target: {
            value: '',
          },
        });
      }
    } else {
      messageStore.setMessage({
        content: 'Listname cannot be empty',
        type: MessageType.error,
      });
    }
  };

  return (
    <div className="custom-lists-header">
      {newListActive
        ? (
          <div className="custom-lists-header__new-list">
            <TextField
              {...newList}
              outerClassName="custom-lists-header__new-list__input"
              onKeyPress={(e) => (e.which === 13 ? addNewList() : null)}
              isPrimary
            >
              Name
            </TextField>
            <Button
              onClick={addNewList}
              className="custom-lists-header__new-list__submit"
              isIconFloating
              icon={{ type: 's', name: 'save' }}
            />
          </div>
        )
        : (
          <Button
            isPrimary
            onClick={() => setNewListActive(true)}
          >
            New Launchlist
          </Button>
        )}
    </div>
  );
});
