import handleError from './error-handler';

/**
 * wrapper function to execute a function and pass the error
 * to the error handler
 * @param toExecute
 */
const execute = async (toExecute: any) => {
	try {
		const response = await toExecute();
		return response;
	} catch (e) {
		handleError(e);
		throw e;
	}
};

export default execute;
