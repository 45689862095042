import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/scss/main.scss';
import * as serviceWorker from './serviceWorker';
import firebase from './firebase';

let appLoaded = false;

firebase.auth().onAuthStateChanged(() => {
  if (!appLoaded) {
    ReactDOM.render(<App />, document.getElementById('root'));
  }
  appLoaded = true;
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
