import React from 'react';
import './ChangeEmailLink.scss';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Icon from '../Icon/Icon';

interface ChangeEmailLinkProps {
  email: string
}

export default observer(({ email } : ChangeEmailLinkProps) => (
  <div className="change-email-link">
    <h3 className="h5">Email:</h3>
    <div className="change-email-link__content">
      <p className="p change-email-link__p">{email}</p>
      <Link to="/me/change-email" className="change-email-link__link">
        <Icon faType="l" icon="pen" />
        edit email
      </Link>
    </div>
  </div>
));
