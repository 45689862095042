import React from 'react';
import './Tabs.scss';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useHistory, Link } from 'react-router-dom';

interface TabsProps {
    nav: {
      path: string,
      name: string
    }[]
}

export default observer(({ nav } : TabsProps) => {
  const history = useHistory();

  return (
    <nav className="tab-nav">
      <ul className="tab-nav__list">
        {nav.map((item) => (
          <li
            key={item.path}
            className={classNames({
              'tab-nav__list__item': true,
              'tab-nav__list__item--active': history.location.pathname === item.path,
            })}
          >
            <Link
              to={item.path}
              className="tab-nav__list__item__button"
            >
              {item.name}
            </Link>
          </li>
        ))}

      </ul>
    </nav>
  );
});
