import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Button from '../../Button/Button';
import { useListStore, useUserStore } from '../../../stores';
import Icon from '../../Icon/Icon';

interface ItemProps {
  name: string
}

export default observer(({ name }: ItemProps) => {
  const history = useHistory();

  const listStore = useListStore();
  const userStore = useUserStore();

  const redirect = () => {
    history.push(`/lists/${name}`);
  };

  const deleteList = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (userStore.user) {
      listStore.removeList(name, userStore.user);
    }
  };

  return (
    <li
      role="link"
      tabIndex={0}
      onClick={redirect}
      onKeyUp={(e) => (e.which === 13 ? redirect() : null)}
      className="ul__li custom-list__item"
    >
      <Button
        onClick={deleteList}
        icon={{ type: 's', name: 'trash' }}
      />
      <span className="custom-list__item__name">{name}</span>
      <Icon className="custom-list__item__angle" faType="l" icon="angle-right" />
    </li>
  );
});
