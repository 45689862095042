import React from 'react';
import { observer } from 'mobx-react';
import './Overlay.scss';
import classNames from 'classnames';
import { useOverlayStore } from '../../stores';
import Button from '../Button/Button';

export default observer(() => {
  const { overlay, exitOverlay } = useOverlayStore();

  if (!overlay) return null;

  return (
    <div
      style={overlay.overlayStyles}
      className={`overlay ${overlay.overlayClassName ? overlay.overlayClassName : ''}`}
    >
      {!overlay.withoutExit
        ? (
          <Button
            onClick={() => exitOverlay()}
            icon={{ name: 'times', type: 'l' }}
            className="overlay__close"
          />
        )
        : null}
      <div className={`${classNames({
        overlay__body: true,
        'overlay__body--bottom': overlay.isBottom,
      })} ${overlay.overlayBodyClassName ? overlay.overlayBodyClassName : ''}`}
      >
        <overlay.content />
      </div>
    </div>
  );
});
