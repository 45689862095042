import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import './ListOverview.scss';
import { useHistory, useParams } from 'react-router-dom';
import { useUserStore, useMessageStore, useListStore } from '../../stores';
import { MessageType } from '../../models/IMessage';
import PlanetHeader from '../../components/PlanetHeader/PlanetHeader';
import LaunchList from '../../components/LaunchList/LaunchList';
import { useInitDefault } from '../../hooks';
import Loader from '../../components/Loader/Loader';

export default observer(() => {
  const listStore = useListStore();
  const userStore = useUserStore();
  const messageStore = useMessageStore();

  useInitDefault();

  const history = useHistory();
  const { name } = useParams();

  if (!userStore.user) {
    messageStore.setMessage({
      content: 'You have to be logged in',
      type: MessageType.error,
    });
    history.push('/');
  }

  useEffect(() => {
    if (name) {
      listStore.setCurrentList(name);
    }

    if (userStore.user) {
      listStore.listenToLists(userStore.user);
    }

    return () => {
      if (userStore.user) {
        listStore.removeCurrentListListener(userStore.user);
      }
    };
  }, []);

  if (!listStore.currentList && !listStore.selectedList) return null;

  return (
    <Loader isLoading={!listStore.fetched}>
      <div className="favourites landing">
        {listStore.currentList && listStore.selectedList && listStore.selectedList.length > 0
          ? (
            <>
              <PlanetHeader nextDate={listStore.selectedList[0].date} />
              <div className="container">
                <LaunchList
                  isBig
                  className="landing__list landing__list--margin-negative"
                  launches={listStore.selectedList}
                  title={listStore.currentList}
                />
              </div>
            </>
          )
          : (
            <div className="no-content">
              You don&apos;t have any launches in this list yet.
            </div>
          )}
      </div>
    </Loader>

  );
});
