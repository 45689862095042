import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import ILaunch from '../../models/ILaunch';
import CustomListsHeader from '../CustomListsHeader/CustomListsHeader';
import { useListStore, useUserStore } from '../../stores';
import Item from './Item/Item';
import './AddToList.scss';

interface AddToListProps {
  launch: ILaunch
}

export default observer(({ launch } : AddToListProps) => {
  const listStore = useListStore();
  const userStore = useUserStore();

  useEffect(() => {
    if (!listStore.lists && userStore.user) {
      listStore.listenToLists(userStore.user);
    }

    return () => {
      if (userStore.user) {
        listStore.removeCurrentListListener(userStore.user);
      }
    };
  }, []);

  return (
    <div className="add-to-list">
      <CustomListsHeader />
      {listStore.lists
        ? (
          <ul className="ul custom-list add-to-list__list">
            {Object.values(listStore.lists).map((list) => (
              <Item key={list.name} launch={launch} name={list.name} />
            ))}
          </ul>
        )
        : null}

    </div>
  );
});
