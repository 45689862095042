import React from 'react';
import './LaunchCountdown.scss';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import Countdown from 'react-countdown';
import CountdownRenderer from './CountdownRenderer/CountdownRenderer';

interface LaunchCountdownProps {
    date: Date | string,
    isWhite?: boolean,
    title?: string
}

export default observer(({ date, isWhite = false, title = 'Next Launch' } : LaunchCountdownProps) => (
  <div className="launch-countdown">
    <h2 className={classNames({
      h2: true,
      'launch-countdown__label': true,
      'launch-countdown__label--white': isWhite,
    })}
    >
      {title}
    </h2>
    <p className={classNames({
      'launch-countdown__timer': true,
      'launch-countdown__timer--white': isWhite,
    })}
    >
      <Countdown
        date={date}
        renderer={(props) => (
          <CountdownRenderer {...props} />
        )}
      />
    </p>
  </div>
));
