import React from 'react';
import { observer } from 'mobx-react';
import './LaunchDetail.scss';
import ILaunch from '../../models/ILaunch';
import SocialButtons from '../SocialButtons/SocialButtons';

interface LaunchDetailProps {
  launch: ILaunch
}

export default observer(({ launch } : LaunchDetailProps) => (
  <div className="launch-detail card">
    <div className="launch-detail__section">
      <h2 className="launch-detail__header h3">{launch.title}</h2>
    </div>
    <div className="launch-detail__section">
      <h3 className="launch-detail__section-header h4">Launch Agency:</h3>
      <p className="p">{launch.lsp.name}</p>
    </div>
    <div className="launch-detail__section">
      <h3 className="launch-detail__section-header h4">Vehicle:</h3>
      <p className="p">{launch.rocket.name}</p>
    </div>
    <SocialButtons launch={launch} />
  </div>
));
