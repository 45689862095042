import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Landing from './views/Landing/Landing';
import Header from './components/Header/Header';
import Navigation from './components/Navigation/Navigation';
import Overlay from './components/Overlay/Overlay';
import Favourites from './views/Favourites/Favourites';
import Lists from './views/Lists/Lists';
import ListOverview from './views/ListOverview/ListOverview';
import Launch from './views/Launch/Launch';
import Vehicle from './views/Vehicle/Vehicle';
import Chat from './views/Chat/Chat';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import Me from './views/Me/Me';
import ChangeEmail from './views/ChangeEmail/ChangeEmail';
import ChangePass from './views/ChangePass/ChangePass';
import Login from './views/Login/Login';
import Register from './views/Register/Register';
import ResetPassword from './views/ResetPassword/ResetPassword';
import Invite from './views/Invite/Invite';
import Imprint from './views/Imprint/Imprint';

export default observer(({ children }) => (
  <Router>
    <Header />
    <Overlay />
    {children}
    <main className="app__content">
      <Route exact path="/" component={Landing} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/imprint" component={Imprint} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/favourites" component={Favourites} />
      <Route exact path="/lists" component={Lists} />
      <Route exact path="/lists/:name" component={ListOverview} />
      <Route exact path="/launch/:id" component={Launch} />
      <Route exact path="/launch/:id/vehicle" component={Vehicle} />
      <ProtectedRoute exact path="/launch/:id/chat" component={Chat} />
      <ProtectedRoute exact path="/launch/:id/invite/:chatId" component={Invite} />
      <ProtectedRoute exact path="/me" component={Me} />
      <ProtectedRoute exact path="/me/change-email" component={ChangeEmail} />
      <ProtectedRoute exact path="/me/change-pass" component={ChangePass} />
    </main>
    <Navigation />
  </Router>
));
