import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '../../Button/Button';
import {
  useListStore, useUserStore, useOverlayStore, useMessageStore,
} from '../../../stores';
import ILaunch from '../../../models/ILaunch';
import { MessageType } from '../../../models/IMessage';

interface ItemProps {
  name: string,
  launch: ILaunch
}

export default observer(({ name, launch }: ItemProps) => {
  const listStore = useListStore();
  const userStore = useUserStore();
  const overlayStore = useOverlayStore();
  const messageStore = useMessageStore();

  const addToList = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (userStore.user) {
      listStore.addLaunch(name, launch, userStore.user);
      overlayStore.exitOverlay();
      messageStore.setMessage({
        content: `Added ${launch.title} to ${name}`,
        type: MessageType.success,
      });
    }
  };

  return (
    <li
      className="ul__li add-to-list__item"
    >
      <Button
        onClick={addToList}
        className="add-to-list__item__add-button"
        icon={{ type: 'l', name: 'plus' }}
      />
      <span className="custom-list__item__name">{name}</span>
    </li>
  );
});
