import { observable, action } from 'mobx';
import firebase from '../firebase';
import ILaunch from '../models/ILaunch';
import IUser from '../models/IUser';
import { cloneDeep } from 'lodash';
import messageStore from './MessageStore';
import { MessageType } from '../models/IMessage';

class FavouriteStore {
  @observable items: ILaunch[] | null = null;
  
  @observable fetching: boolean = false;

  @action reset() {
    this.fetching = false;
    this.items = null;
  }

	@action async fetch(user: IUser) {
    if (!this.fetching) {
      this.fetching = true;

      const favSnapshot = await firebase.database().ref(`/favourites/${user.uid}`).once('value');
  
      const favourites = favSnapshot.val();
      if (favourites) {
        this.items = Object.values(favourites);
      }
      this.fetching = false;
    }
  }
  
  isFavourite(launch: ILaunch) {
    if (!this.items) return false;

    for (let item of this.items) {
      if (item.id === launch.id) {
        return true;
      }
    }

    return false;
  }

	@action async addLaunch(launch: ILaunch, user: IUser) {
		try {
			await firebase.database().ref(`/favourites/${user.uid}/${launch.id}`).set(launch);
			if (this.items) {
				let unique = true;
				for (let item of this.items) {
					if (item.id === launch.id) {
						unique = false;
					}
				}
				if (unique) {
          this.items = [
            ...this.items,
            launch
          ];
				}
			}
		} catch(e) {
      messageStore.setMessage({
        content: 'Failed to add launch to list',
        type: MessageType.error
      });
		}
	}

	@action async removeLaunch(launchID: number, user: IUser) {
		try {
			await firebase.database().ref(`/favourites/${user.uid}/${launchID}`).remove();
			if (this.items) {
				this.items = this.items.filter((item) => item.id !== launchID);
			}

		} catch(e) {
			messageStore.setMessage({
        content: 'Failed to remove launch from list',
        type: MessageType.error
      });
		}
	}
}

export default new FavouriteStore();
