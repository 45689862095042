import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useUserStore, useMessageStore } from '../../stores';
import { MessageType } from '../../models/IMessage';

interface ProtectedRouteInterface extends RouteProps { }

export default observer(({ component, ...rest }: ProtectedRouteInterface) => {
  const userStore = useUserStore();
  const messageStore = useMessageStore();

  const redirect = () => {
    messageStore.setMessage({
      content: 'You have to be logged in.',
      type: MessageType.error,
    });

    return (
      <Redirect to="/login" />
    );
  };

  if (userStore.user) {
    return (
      <Route {...rest} component={component} />
    );
  }

  return (
    <Route {...rest} component={redirect} />
  );
});
