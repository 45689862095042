import { useFavouriteStore, useUserStore } from "../stores";
import { useEffect, useState } from "react";
import ILaunch from "../models/ILaunch";

export default function useFav(launch: ILaunch): [ () => void, boolean ] {
  const [ isFavourite, setIsFavourite ] = useState<boolean>(false);

  const favStore = useFavouriteStore();
  const userStore = useUserStore();

  if (!favStore.items && userStore.user && !favStore.fetching) {
    favStore.fetch(userStore.user);
  }

  useEffect(() => {
    setIsFavourite(favStore.isFavourite(launch))
  }, [ favStore.items ])

	const handler = () => {
    if (userStore.user) {
      if (favStore.isFavourite(launch)) {
        favStore.removeLaunch(launch.id, userStore.user);
      } else {
        favStore.addLaunch(launch, userStore.user);
      }
    }
	}
	return [
    handler,
    isFavourite
	];
}