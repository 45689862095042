import { observable, action } from 'mobx';
import firebase from '../firebase';
import IUser from '../models/IUser';
import favouriteStore from './FavouriteStore';
import messageStore from './MessageStore';
import { MessageType } from '../models/IMessage';

class UserStore {
	@observable user: IUser | null = null;

  private facebookProvider = new firebase.auth.FacebookAuthProvider();
  private twitterProvider = new firebase.auth.TwitterAuthProvider();

	constructor() {
		firebase.auth().onAuthStateChanged((user) => {
      favouriteStore.reset();
			if (user) {
				const { displayName, email, emailVerified, photoURL, uid } = user;
				this.user = {
					uid,
					displayName,
					photoURL,
					email,
					emailVerified
				};
			} else {
				this.user = null;
			}
		});
  }
  
  @action async signInWithFacebook() {
    try {
			await firebase.auth().signInWithPopup(this.facebookProvider);
		} catch (e) {
			throw e;
		}
  }

  @action async signInWithTwitter() {
    try {
      const twitterResult = await firebase.auth().signInWithPopup(this.twitterProvider);
		} catch (e) {
			throw e;
		}
  }

  @action async resetPassword(email: string) {
    try {
			await firebase.auth().sendPasswordResetEmail(email);
		} catch (e) {
			throw e;
		}
  }

	@action async register(email: string, password: string, username: string) {
		try {
			const cred = await firebase.auth().createUserWithEmailAndPassword(email, password);

			if (cred.user) {
				await cred.user.updateProfile({
					displayName: username
				});
				if (this.user) {
					this.user.displayName = username;
				}
			}
		} catch (e) {
			throw e;
		}
	}

  @action async changeEmail(newEmail: string) {
    try {
      const currentFirebaseUser = firebase.auth().currentUser;
      if (currentFirebaseUser) {
        await currentFirebaseUser.updateEmail(newEmail);
        if (this.user) {
          this.user.email = newEmail;
        }
      }
    } catch (e) {
      throw e;
    }
  }

  @action async changePass(newPassword: string) {
    try {
      const currentFirebaseUser = firebase.auth().currentUser;
      if (currentFirebaseUser) {
        await currentFirebaseUser.updatePassword(newPassword);
      }
    } catch (e) {
      throw e;
    }
  }

	@action async login(email: string, password: string) {
		try {
			await firebase.auth().signInWithEmailAndPassword(email, password);
		} catch (e) {
      throw e;
		}
	}

	@action async logout() {
		try {
			await firebase.auth().signOut();
		} catch (e) {
			messageStore.setMessage({
        content: 'Failed to logout.',
        type: MessageType.error
      });
		}
	}
}

export default new UserStore();
