import React, { FormEvent, useState } from 'react';
import './RegisterForm.scss';
import { observer } from 'mobx-react';
import { useInput } from 'rooks';
import { useHistory } from 'react-router-dom';
import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import { useMessageStore, useUserStore } from '../../stores';
import { MessageType } from '../../models/IMessage';

export default observer(() => {
  const email = useInput('');
  const password = useInput('');
  const username = useInput('');

  const [loading, setLoading] = useState<boolean>(false);

  const messageStore = useMessageStore();
  const userStore = useUserStore();

  const history = useHistory();

  const register = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (email.value === '' || !email.value) {
      messageStore.setMessage({
        content: 'Email cannot be blank',
        type: MessageType.error,
      });
    }

    if (username.value === '' || !username.value) {
      messageStore.setMessage({
        content: 'Username cannot be blank',
        type: MessageType.error,
      });
    }

    if (password.value === '' || !password.value) {
      messageStore.setMessage({
        content: 'Password cannot be blank',
        type: MessageType.error,
      });
    }

    try {
      await userStore.register(email.value, password.value, username.value);

      messageStore.setMessage({
        content: 'Successfully registered.',
        type: MessageType.success,
      });

      history.push('/');
    } catch (error) {
      messageStore.setMessage({
        content: error.message,
        type: MessageType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={register}
      className="change-email__form"
    >
      <TextField
        {...email}
        type="email"
        name="email"
        isPrimary
      >
        Email
      </TextField>
      <TextField
        {...username}
        type="text"
        name="username"
        isPrimary
      >
        Username
      </TextField>
      <TextField
        {...password}
        type="password"
        name="password"
        isPrimary
      >
        Password
      </TextField>

      <div className="change-email__form__footer">
        <Button
          className="login-form__submit"
          type="submit"
          isBorderBottom
          isIconFloating
          isLoading={loading}
          icon={{ type: 'd', name: 'angle-right' }}
        >
          Register
        </Button>
      </div>
    </form>
  );
});
