import React from 'react';
import { observer } from 'mobx-react';
import Clipboard from 'react-clipboard.js';
import Icon from '../../Icon/Icon';
import { ShareListProps } from '../ShareList';
import { useMessageStore } from '../../../stores';
import { MessageType } from '../../../models/IMessage';

export default observer(({ url } : ShareListProps) => {
  const messageStore = useMessageStore();

  const shareSuccess = () => {
    messageStore.setMessage({
      content: 'Copied link to clipboard.',
      type: MessageType.success,
    });
  };

  return (
    <Clipboard onSuccess={shareSuccess} component="li" className="share-list__list-item ul__li ul__li--without-border" data-clipboard-text={url}>
      <span className="share-list__icon share-list__icon--url">
        <Icon className="share-list__icon__svg-wrapper" faType="l" icon="link" />
      </span>
      <span className="share-list__text">
        Copy Link
      </span>
      <span className="share-list__angle-right">
        <Icon faType="l" icon="angle-right" />
      </span>
    </Clipboard>
  );
});
