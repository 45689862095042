import React from 'react';
import { useOverlayStore } from '../stores';
import BottomOverlayHeader from '../components/BottomOverlayHeader/BottomOverlayHeader';
import ILaunch from '../models/ILaunch';
import AddToList from '../components/AddToList/AddToList';

export default function useAddToList(launch: ILaunch) {
  const overlayStore = useOverlayStore();

  const handler = () => {
    overlayStore.setOverlay({
      content: () => (
        <div className="share-overlay">
          <BottomOverlayHeader title="Add to launchlist" />
          <AddToList launch={launch} />
        </div>
      ),
      overlayBodyClassName: 'overlay__body--white',
      isBottom: true,
      withoutExit: true,
    });
  };
  return [
    handler,
  ];
}
