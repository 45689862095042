import React, { useEffect, useState } from 'react';
import './ChatHistory.scss';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useChatStore, useUserStore } from '../../stores';
import ChatBubble from '../ChatBubble/ChatBubble';

export default observer(() => {
  const chatStore = useChatStore();
  const userStore = useUserStore();

  const [historyRef, setHistoryRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (historyRef) {
      historyRef.scrollTo(0, historyRef.scrollHeight);
    }
  }, [historyRef, chatStore.chat]);

  if (!chatStore.chat || !userStore.user || !chatStore.chat.comments) return null;

  return (
    <div className="chat-history" ref={(el) => setHistoryRef(el)}>
      {Object.values(chatStore.chat.comments).sort((aComment, bComment) => {
        if (new Date(aComment.date) < new Date(bComment.date)) {
          return -1;
        }
        if (new Date(aComment.date) > new Date(bComment.date)) {
          return 1;
        }
        return 0;
      }).map((comment) => {
        const isSelf = userStore.user ? userStore.user.uid === comment.uid : false;
        return (
          <div
            key={`${comment.content}-${comment.date}`}
            className={classNames({
              'chat-history__chat-bubble-wrapper': true,
              'chat-history__chat-bubble-wrapper--right': isSelf,
            })}
          >
            <ChatBubble
              message={{
                content: comment.content,
                name: isSelf ? undefined : comment.username,
              }}
              self={isSelf}
            />
          </div>
        );
      })}
    </div>
  );
});
