import React from 'react';
import { observer } from 'mobx-react-lite';
import './LogoutButton.scss';
import { useHistory } from 'react-router-dom';
import Button from '../Button/Button';
import { useUserStore } from '../../stores';

export default observer(() => {
  const userStore = useUserStore();

  const history = useHistory();

  const logoutHandler = () => {
    if (userStore.user) {
      userStore.logout();
      history.push('/');
    }
  };

  return (
    <Button
      className="logout-button"
      onClick={logoutHandler}
      icon={{ type: 'l', name: 'sign-out' }}
    />
  );
});
