export default function checkPath(path: string, includes: string[]) {
  const locationParts = path.split('/');
  for (let includePath of includes) {
    let isPath = false;
    let tempPath = includePath.split('/');
    if (tempPath.length === locationParts.length) {
      for (let i = 0; i < locationParts.length; i++) {
      
        if (!tempPath[i].startsWith(':') && tempPath[i] !== locationParts[i]) {
          isPath = false;
          break;
        } else {
          isPath = true;
        }
      }
    }
    
    if (isPath) {
      return true;
    }
  }
  return false;
}