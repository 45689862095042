import React, { ButtonHTMLAttributes } from 'react';
import './Button.scss';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    isPrimary?: boolean,
    icon?: {
        type?: 'l' | 'd' | 'b' | 'r' | 's',
        name: string
    },
    isIconFloating?: boolean,
    isBorderBottom?: boolean,
    isLoading?: boolean
}

export default observer(({
  isPrimary,
  isIconFloating,
  isBorderBottom,
  isLoading = false,
  icon,
  children,
  className,
  type,
  ...rest
} : ButtonProps) => {
  let btnType: 'button' | 'submit' | 'reset' | undefined = 'button';
  if (type === 'submit') {
    btnType = 'submit';
  }
  if (type === 'reset') {
    btnType = 'reset';
  }
  /* eslint-disable react/button-has-type */
  return (
    <button
      {...rest}
      type={btnType}
      disabled={isLoading}
      className={`btn ${classNames({
        'btn--primary': isPrimary,
      })} ${className}`}
    >

      {children
        ? (
          <span className={`btn__item ${classNames({
            'btn__item--border-bottom': isBorderBottom,
          })}`}
          >
            {children}
          </span>
        )
        : null}

      {icon
        ? (
          <>
            {isLoading
              ? (
                <div className="btn__item btn__loader">
                  <div className="btn__loader__spinner" />
                </div>
              )
              : (
                <Icon
                  className={`btn__item btn__icon ${classNames({
                    'btn__icon--floating': isIconFloating,
                  })}`}
                  icon={icon.name}
                  faType={icon.type ? icon.type : undefined}
                />
              )}
          </>
        )
        : null}
    </button>
  );
  /* eslint-enable react/button-has-type */
});
