import React from 'react';
import { observer } from 'mobx-react';
import './CustomLists.scss';
import Item from './Item/Item';

interface CustomListsProps {
  lists: string[]
}

export default observer(({ lists } : CustomListsProps) => (
  <ul className="ul custom-list">
    {lists.map((list) => (
      <Item key={list} name={list} />
    ))}
  </ul>
));
