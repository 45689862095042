import React, { useState } from 'react';
import './PreviewCard.scss';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import Countdown from 'react-countdown';
import useShareing from '../../hooks/share';
import { useFav } from '../../hooks';
import Button from '../Button/Button';
import CountdownRenderer from './CountdownRenderer/CountdownRenderer';
import ILaunch from '../../models/ILaunch';
import { useUserStore, useSelectedLaunchStore } from '../../stores';

interface PreviewCardProps {
    launch: ILaunch,
    isBig?: boolean
}

export default observer(({ launch, isBig = false } : PreviewCardProps) => {
  const [shareHandler] = useShareing(launch);
  const [favHandler, isFavourite] = useFav(launch);

  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  const userStore = useUserStore();
  const selectedLaunchStore = useSelectedLaunchStore();

  const history = useHistory();

  const redirect = () => {
    selectedLaunchStore.setItem(launch);
    history.push(`/launch/${launch.id}`);
  };

  return (
    <div
      role="link"
      tabIndex={0}
      className={classNames({
        'preview-card': true,
        'preview-card--small': !isBig,
        'preview-card--big': isBig,
        'preview-card--loading': !isImageLoaded,
      })}
      onKeyUp={(e) => (e.which === 13 ? redirect() : null)}
      onClick={redirect}
    >
      <img
        onLoad={() => setIsImageLoaded(true)}
        className="preview-card__image"
        alt={launch.title}
        src={launch.rocket.imageFormats['480']}
      />
      <div className="preview-card__content">
        {isBig
          ? (
            <div className="preview-card__nav">

              <Button
                onClick={(e) => { e.stopPropagation(); shareHandler(); }}
                icon={{ name: 'share-alt', type: 'l' }}
                className="preview-card__nav__item preview-card__nav__item--share"
              />
              {userStore.user
                ? (
                  <Button
                    onClick={(e) => { e.stopPropagation(); favHandler(); }}
                    icon={{ name: 'heart', type: 's' }}
                    className={classNames({
                      'preview-card__nav__item': true,
                      'preview-card__nav__item--heart': true,
                      'preview-card__nav__item--heart--active': isFavourite,
                    })}
                  />
                )
                : null}
            </div>
          )
          : (
            <Countdown
              date={launch.date}
              renderer={(props) => (
                <CountdownRenderer isBig={isBig} {...props} />
              )}
            />
          )}

        <h3 className={classNames({
          'preview-card__content__title': true,
          'preview-card__content__title--center': isBig,
        })}
        >
          {launch.title}
        </h3>

        {isBig
          ? (
            <Countdown
              date={launch.date}
              renderer={(props) => (
                <CountdownRenderer isBig={isBig} {...props} />
              )}
            />
          )
          : null}
      </div>
    </div>
  );
});
