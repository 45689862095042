import React, { useEffect, useState } from 'react';
import './Landing.scss';
import { observer } from 'mobx-react';
import PlanetHeader from '../../components/PlanetHeader/PlanetHeader';
import { useLaunchStore } from '../../stores';
import ILaunch from '../../models/ILaunch';
import LaunchList from '../../components/LaunchList/LaunchList';
import { useInitDefault } from '../../hooks';
import Loader from '../../components/Loader/Loader';

interface ICurrentLaunches {
  nextDate: string,
  upcoming: ILaunch[],
  remaining: ILaunch[];
}

export default observer(() => {
  const launchStore = useLaunchStore();

  useInitDefault();

  const [currentLaunches, setCurrentLaunches] = useState<ICurrentLaunches>();

  useEffect(() => {
    if (!launchStore.launches) {
      launchStore.fetch();
    }
  }, []);

  useEffect(() => {
    if (launchStore.launches) {
      const temp: ICurrentLaunches = {
        nextDate: '',
        upcoming: [],
        remaining: [],
      };
      for (let i = 0; i < launchStore.launches.length; i++) {
        if (temp.nextDate === '' && new Date(launchStore.launches[i].date) > new Date()) {
          temp.nextDate = launchStore.launches[i].date;
          temp.upcoming.push(launchStore.launches[i]);
        } else if (i < 3) {
          temp.upcoming.push(launchStore.launches[i]);
        } else {
          temp.remaining.push(launchStore.launches[i]);
        }
      }
      setCurrentLaunches(temp);
    }
  }, [launchStore.launches]);

  return (
    <Loader isLoading={launchStore.isLoading}>
      <div className="landing">
        {currentLaunches
          ? (
            <>
              <PlanetHeader nextDate={currentLaunches.nextDate} />
              <div className="container">
                {currentLaunches.upcoming
                  ? (
                    <LaunchList
                      className="landing__list landing__list--margin-negative"
                      launches={currentLaunches.upcoming}
                      title="Upcoming"
                    />
                  )
                  : null}
                {currentLaunches.upcoming
                  ? (
                    <LaunchList
                      className="landing__list"
                      isBig
                      launches={currentLaunches.remaining}
                      title="All Launches"
                    />
                  )
                  : null}
              </div>
            </>
          )
          : null}

      </div>
    </Loader>
  );
});
