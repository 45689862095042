import React from 'react';
import { observer } from 'mobx-react';
import Icon from '../../Icon/Icon';
import { ShareListProps } from '../ShareList';

export default observer(({ launch, url } : ShareListProps) => {
  const share = () => {
    const shareUrl = `https://twitter.com/share?text=I'm watching the ${launch.title} space mission&url=${url}`;

    window.open(shareUrl, 'windowNew', 'width=500, height=500');
  };

  return (
    <li
      role="link"
      tabIndex={0}
      onClick={share}
      onKeyUp={(e) => (e.which === 13 ? share() : null)}
      className="share-list__list-item ul__li ul__li--without-border"
    >
      <span className="share-list__icon share-list__icon--twitter">
        <Icon className="share-list__icon__svg-wrapper" faType="b" icon="twitter" />
      </span>
      <span className="share-list__text">
        Twitter
      </span>
      <span className="share-list__angle-right">
        <Icon faType="l" icon="angle-right" />
      </span>
    </li>
  );
});
